/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_jRzMNuwoc",
    "aws_user_pools_web_client_id": "5djnk8enb6a39077a2vmb49r0e",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "wolfganganalytics",
            "endpoint": "https://r4cztrfec1.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
